import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import { useHandleInputChange, useHandleOnBlurChange } from './InputGroupHooks';
import { CalculationSectionProps, CatenaryDataInputs } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import { initialCatenaryData } from '../InitialData/InitialData';
import { CatenaryVisualization } from '../Vizualisations/Catenary';
import { CatenaryCoordinates } from '../RightSection/rightSectionTypes';

export function Catenary(props: CalculationSectionProps): ReactElement {
  const [catenaryCoordinates, setCatenaryCoordinates] =
    useState<CatenaryCoordinates>({ xCoords: [], yCoords: [] });

  useEffect(() => {
    if (props.resultData) {
      const catenaryCoordinateList = props.resultData.data.catenaryCoordinates;
      const catenaryCoordinate3 = catenaryCoordinateList[2] || [];
      let catenaryCoordinates = catenaryCoordinateList[0];
      if (props.id === 'Catenary-2') {
        catenaryCoordinates = catenaryCoordinateList[1];
      }
      if (catenaryCoordinate3.length > 0 && catenaryCoordinate3[0].length > 0) {
        catenaryCoordinates = catenaryCoordinate3;
      }
      setCatenaryCoordinates({
        xCoords: catenaryCoordinates[0] as number[],
        yCoords: catenaryCoordinates[1] as number[],
      });
    }
  }, [props.resultData, props.id]);

  const containerRef = useRef<HTMLDivElement>(null);

  const initialData: CalculationDataInputs =
    props.applicationData.calculationsData.find(
      (section: CalculationDataInputs): boolean => section.id === props.id
    ) || initialCatenaryData;
  const [catenaryData, setCatenaryData] = useState<CatenaryDataInputs>(
    initialData as CatenaryDataInputs
  );

  const handleInputChange = useHandleInputChange(setCatenaryData);
  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: catenaryData,
    calculationDataState: props.calculationDataState,
  });

  return (
    <>
      <StyledDiv ref={containerRef}>
        <InputSection>
          <CategoryHeader title="Catenary" underline={false} />
          {Object.entries(catenaryData.data).map(
            ([key, input]: [string, DataInputs]) => {
              const isValueOk = (value: number) => value > 0 && value < 1000;
              return (
                <DataInput
                  key={key}
                  name={input.name}
                  symbol={input.symbol}
                  value={input.value}
                  placeholder={input.value}
                  unit={input.unit}
                  valueOk={isValueOk(input.value)}
                  onChange={handleInputChange(
                    key as keyof CatenaryDataInputs['data'],
                    isValueOk
                  )}
                  onBlur={handleOnBlurChange}
                  restrictionText={'Value must be between 0 and 1000'}
                />
              );
            }
          )}
        </InputSection>
        <CatenaryVisualization
          catenaryData={catenaryData}
          catenaryCoordinates={catenaryCoordinates}
        />
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
