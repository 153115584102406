import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { ReactComponent as AddIcon } from '../../assets/buttons_and_icons/AddIcon.svg';
import { ReactComponent as CopyIcon } from '../../assets/buttons_and_icons/CopyIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/buttons_and_icons/DeleteIcon.svg';
import { ReactComponent as RotateIcon } from '../../assets/RotateIcon.svg';
import { ReactComponent as RedExclamation } from '../../assets/buttons_and_icons/RedExclamation.svg';
import { useModularCableTrack } from './ModularCableTrackHooks';
import {
  CalculationSectionProps,
  ModularCableData,
  ModularCableInputs,
} from './inputGroupTypes';
import { useHandleOnBlurChange } from './InputGroupHooks';
import { DataInput } from '../Inputs/DataInput';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import { initialMCTData } from '../InitialData/InitialData';
import { ModularCableTrackVisualizations } from '../Vizualisations/ModularCableTrack';
import { TooltipMCT } from '../Tooltip/TooltipMCT';
import { MCTIconButtonProps } from '../Button/buttonTypes';
import Konva from 'konva';
import Stage = Konva.Stage;
import { useAppData } from '../Contexts/DataContext/DataContext';

const initialMCTColumn: ModularCableData = {
  length: {
    value: 15,
    unit: 'm',
    valueOk: true,
  },
  angle: {
    value: 0,
    unit: 'deg',
    valueOk: true,
  },
  friction: {
    value: 0.15,
    unit: '',
    valueOk: true,
  },
};

export function ModularCableTrack(
  props: CalculationSectionProps
): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setcontainerWidth] = useState<number>(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { clientWidth } = containerRef.current;
        setcontainerWidth(clientWidth);
      }
    };

    // Initial set
    updateDimensions();

    // Add event listener
    window.addEventListener('resize', updateDimensions);

    // Cleanup listener
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const initialData = (props.applicationData.calculationsData.find(
    (section: CalculationDataInputs): boolean => section.id === props.id
  ) || initialMCTData) as ModularCableInputs;

  const initialPreTension: number = initialData.data.preTension?.value || 0;
  const initialRotation: number = initialData.data.rotation;

  const { dataRow, addRow, copyRow, removeRow, handleInputChange } =
    useModularCableTrack(
      initialData.data.input as ModularCableData | ModularCableData[],
      initialMCTColumn
    );

  const [modularCableData, setModularCableData] =
    useState<ModularCableInputs>(initialData);

  const [preTension, setPreTension] = useState<number>(initialPreTension);
  const [rotation, setRotation] = useState<number>(initialRotation);
  const { selectedScenario } = useScenario();

  const handlePreTensionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newValue = Number(e.target.value);
    setPreTension(newValue);
  };

  useEffect((): void => {
    const newMctData: ModularCableInputs = modularCableData;
    if (selectedScenario === 'Scenario 3: Factory to Tank') {
      newMctData.data.preTension = {
        name: 'Cable Tension at the start of the MCT',
        symbol: 'T_1',
        unit: 'kN',
        value: preTension,
        valueOk: true,
      };
    }
    newMctData.data.input = dataRow;
    newMctData.data.rotation = rotation;
    setModularCableData(newMctData);
  }, [dataRow, modularCableData, preTension, rotation, selectedScenario]);

  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: modularCableData,
    calculationDataState: props.calculationDataState,
  });

  const handleAddRowClick = (): void => {
    addRow();
    handleOnBlurChange();
  };
  const handleCopyRowClick = (index: number): void => {
    copyRow(index);
    handleOnBlurChange();
  };
  const handleRemoveRowClick = (index: number): void => {
    removeRow(index);
    handleOnBlurChange();
  };

  const [selectedSection, setSelectedSection] = useState<number>(0);
  const mctVisualizationRef = useRef<Stage>(null);
  const { includeMctUri, setMctVisualizationData, setIncludeMctUri } =
    useAppData();

  useEffect(() => {
    if (includeMctUri) {
      updateKonvaState();
    }
  }, [includeMctUri]);

  const updateKonvaState = () => {
    if (mctVisualizationRef.current && containerWidth > 0) {
      const uri = mctVisualizationRef.current.toDataURL();
      setMctVisualizationData(uri);
    }
  };

  const sectionRefs = useOutsideClick(() => {
    setSelectedSection(0);
  });

  useEffect(() => {
    updateKonvaState();
  }, [selectedSection]);

  return (
    <>
      <StyledDiv ref={containerRef}>
        <InputSection>
          <CategoryHeader
            title="MCT (Modular Cable Track)"
            underline={false}
            infoModal={<TooltipMCT />}
          />
          {selectedScenario === 'Scenario 3: Factory to Tank' ? (
            <DataInput
              name="Cable Tension at the start of the MCT"
              symbol="T_1"
              unit="kN"
              onBlur={handleOnBlurChange}
              onChange={handlePreTensionChange}
              placeholder={preTension}
              value={preTension}
              valueOk={true}
            ></DataInput>
          ) : null}
          <ModularCableTrackVisualizations
            mctVisualizationRef={mctVisualizationRef}
            containerWidth={containerWidth}
            dataRow={dataRow}
            rotation={rotation}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            setIncludeMctUri={setIncludeMctUri}
            updateKonvaState={updateKonvaState}
          />
          <TableDiv $exceedsWidth={dataRow.length}>
            <GridColumn>
              <GridSection>
                <TitleDiv>Segment</TitleDiv>
                <TitleDiv>
                  Length, <i>L₁</i> [m]
                </TitleDiv>
                <TitleDiv>
                  Angle, <i>Θ</i> [°]
                </TitleDiv>
                <TitleDiv>
                  Friction, <i>µₛᵢ</i> [-]
                </TitleDiv>
              </GridSection>
              <RotateButton
                onClick={() =>
                  setRotation((prev) => {
                    if (prev + 90 !== (0 || 90)) {
                      return 0;
                    }
                    return prev + 90;
                  })
                }
              >
                <RotateIcon />
                Rotate
              </RotateButton>
            </GridColumn>
            {dataRow.map((row: ModularCableData, index: number) => (
              <GridColumn key={index + 1}>
                <SelectableGridSection
                  $isSectionSelected={selectedSection === index + 1}
                  onClick={() => setSelectedSection(index + 1)}
                  ref={sectionRefs}
                >
                  <SegmentDiv>{index + 1}</SegmentDiv>
                  <StyledInput
                    type="number"
                    min={0}
                    max={1000}
                    $valueOk={row.length.value >= 0 && row.length.value <= 1000}
                    placeholder={String(row.length.value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(
                        index,
                        'length',
                        Number(e.target.value)
                      );
                      updateKonvaState();
                    }}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.target.value = '';
                      handleOnBlurChange();
                    }}
                  />
                  <StyledInput
                    type="number"
                    min={-90}
                    max={90}
                    $valueOk={row.angle.value >= -90 && row.angle.value <= 90}
                    placeholder={String(row.angle.value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(index, 'angle', Number(e.target.value));
                      updateKonvaState();
                    }}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.target.value = '';
                      handleOnBlurChange();
                    }}
                  />
                  <StyledInput
                    type="number"
                    min={0}
                    max={10}
                    $valueOk={
                      row.friction.value >= 0 && row.friction.value <= 10
                    }
                    placeholder={String(row.friction.value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(
                        index,
                        'friction',
                        Number(e.target.value)
                      );
                      updateKonvaState();
                    }}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.target.value = '';
                      handleOnBlurChange();
                    }}
                  />
                </SelectableGridSection>
                <IconsDiv>
                  <IconButton
                    imgSrc={CopyIcon}
                    onClick={() => handleCopyRowClick(index)}
                  />
                  <IconButton
                    imgSrc={DeleteIcon}
                    onClick={() => handleRemoveRowClick(index)}
                    delete
                  />
                </IconsDiv>
              </GridColumn>
            ))}
            <GridColumn>
              <TitleDiv />
              <TitleDiv />
              <IconButton
                imgSrc={AddIcon}
                onClick={handleAddRowClick}
                size={'40px'}
              />
              <TitleDiv />
              <IconsDiv />
            </GridColumn>
          </TableDiv>
          <WarningDiv>
            <RedExclamation />
            The minimum bending radius, which is not included in this schematic
            visualisation, should be adhered to. See detailed design drawings.
          </WarningDiv>
        </InputSection>
      </StyledDiv>
    </>
  );
}

const SegmentDiv = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, sans-serif;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1em;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const StyledInput = styled.input<{ $valueOk?: boolean }>`
  width: 80px;
  height: 40px;
  box-shadow: ${({ $valueOk }) =>
    $valueOk
      ? 'inset 0 0 0 1px rgb(204, 204, 204)'
      : 'inset 0 0 0 2px var(--twd_signal)'};
  border: 0;
  border-radius: 8px;
  text-align: center;
  padding: 0;
  margin: 0;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus-visible {
    box-shadow: ${({ $valueOk }) =>
      $valueOk ? 'inset 0 0 0 2px black' : 'inset 0 0 0 2px var(--twd_signal)'};
    border: 0;
    outline: 0;
  }
`;

const TableDiv = styled.div<{ $exceedsWidth: number }>`
  width: 100%;
  display: flex;
  gap: ${({ $exceedsWidth }) =>
    $exceedsWidth < 9 ? '10px' : $exceedsWidth < 10 ? '5px' : '1px'};
`;
const GridColumn = styled.div<{ $isSectionSelected?: boolean }>`
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: white;
  border-radius: 8px;
`;

const GridSection = styled.div`
  width: 100%;
  height: calc(100% - 5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  padding: 0 0 5px 0;
`;

const SelectableGridSection = styled(GridSection)<{
  $isSectionSelected?: boolean;
}>`
  width: calc(100% - 10px);
  padding: 0 5px 5px 5px;
  background-color: ${({ $isSectionSelected }) =>
    $isSectionSelected ? 'var(--twd_aqua_pale_2)' : 'white'};
`;

const TitleDiv = styled.div`
  width: 120px;
  height: 40px;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const WarningDiv = styled.div`
  width: 100%;
  height: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export function IconButton(props: MCTIconButtonProps): ReactElement {
  return (
    <StyledLogoButton
      onClick={props.onClick}
      $size={props.size}
      $delete={props.delete}
    >
      <props.imgSrc />
    </StyledLogoButton>
  );
}

const IconsDiv = styled.div`
  width: 80px;
  height: 40px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RotateButton = styled.button`
  width: 120px;
  height: 40px;
  background-color: white;
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
  border: 0;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 5px;

  &:hover {
    box-shadow: inset 0 0 0 2px var(--twd_web_black);
  }
`;

const StyledLogoButton = styled.button<{ $size?: string; $delete?: boolean }>`
  width: ${(props) => props.$size || '40px'};
  height: ${(props) => props.$size || '40px'};
  border: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;

  svg {
    fill: var(--twd_grey);
  }

  &:hover {
    svg {
      fill: ${(props) =>
        props.$delete ? 'var(--twd_signal)' : 'var(--twd_aqua)'};
    }
  }
`;

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current) callback();
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
