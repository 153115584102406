import React, { ReactElement, useEffect } from 'react';
import { ScenarioBanner } from '../ScenarioBanner/ScenarioBanner';
import {
  scenarioInitialValues,
  ScenarioName,
  scenarios,
} from '../ScenarioBanner/scenarioTypes';
import { ScenarioSelect } from './ScenarioSelect';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { useAppData } from '../Contexts/DataContext/DataContext';
import { ApplicationDataType } from '../Contexts/DataContext/dataContextType';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';
import { fetchCalculationResults } from '../../services/swegApi';
import { CableTransferAnalysis } from '../RightSection/rightSectionTypes';

export function ProjectSetupTab(): ReactElement {
  const {
    selectedScenario,
    isInitialized,
    setSelectedScenario,
    setIsInitialized,
  } = useScenario();
  const {
    applicationData,
    setApplicationData,
    setResultsData,
    setResultsLoading,
    setDataValid,
  } = useAppData();

  useEffect(() => {
    if (!isInitialized) {
      handleScenarioChange(selectedScenario);
      setIsInitialized(true);
    }
  }, []);

  const handleScenarioChange = (scenario: ScenarioName): void => {
    const updatedAppData: ApplicationDataType = applicationData;
    updatedAppData.selectedScenario = scenario;
    updatedAppData.calculationsData = scenarioInitialValues[
      scenario
    ] as CalculationDataInputs[];
    setResultsData(undefined);

    const fetchData = async () => {
      setResultsLoading(true);
      const data: Response | undefined =
        await fetchCalculationResults(applicationData);
      if (data?.ok) {
        const result: CableTransferAnalysis =
          (await data.json()) as CableTransferAnalysis;

        setResultsData(result);
        setDataValid(true);
      } else {
        setDataValid(false);
      }
      setResultsLoading(false);
    };
    void fetchData();

    setSelectedScenario(scenario);
    setApplicationData(updatedAppData);
  };

  return (
    <>
      <ScenarioSelect options={scenarios} onSelect={handleScenarioChange} />
      <ScenarioBanner scenario={selectedScenario} />
    </>
  );
}
